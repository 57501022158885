import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
const fishersExactTest = require("fishers-exact-test");
var chi2test = require("@stdlib/stats-chi2test");

interface IFormInput {
  aConversions: number;
  aVisitors: number;
  bConversions: number;
  bVisitors: number;
}

function App() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>();
  const [pValue, setPValue] = useState<number>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (data.aVisitors < 100 || data.bVisitors < 100) {
      const pValues = fishersExactTest(
        data.aConversions,
        data.bConversions,
        data.aVisitors - data.aConversions,
        data.bVisitors - data.bConversions
      );
      console.log(pValues);
      setPValue(pValues.leftPValue);
    } else {
      const table = [
        [data.aConversions, data.bConversions],
        [
          data.aVisitors - data.aConversions,
          data.bVisitors - data.bConversions,
        ],
      ];
      const chi2 = chi2test(table);
      console.log(chi2);
      setPValue(chi2.pValue);
    }
  };

  return (
    <main className="container mx-auto py-8">
      <div className="mt-32 text-center">
        <h1 className="text-6xl font-bold mb-16">
          What's the p-value of your A/B test
        </h1>
        <p className="mt-6">
          In other words, how probable is that the variants perform equally
        </p>
        <p className="mt-2">
          <small>
            Calculated with{" "}
            <a
              className="underline"
              href="https://en.wikipedia.org/wiki/Fisher%27s_exact_test"
            >
              Fisher's exact test
            </a>{" "}
            for small sample sizes and{" "}
            <a
              className="underline"
              href="https://en.wikipedia.org/wiki/Pearson%27s_chi-squared_test"
            >
              Pearson's chi-squared test
            </a>{" "}
            for large sample sizes.
          </small>
        </p>
      </div>
      <div className="mt-16 max-w-md mx-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-x-6 gap-y-4 mb-6 md:grid-cols-2">
            <div>
              <label className="form-label">A Conversions</label>
              <input
                aria-invalid={errors.aConversions ? "true" : "false"}
                {...register("aConversions", {
                  required: "This number is required",
                })}
                className="form-input"
              />
              {errors.aConversions && (
                <div className="text-red-500 mt-2 text-xs" role="alert">
                  {errors.aConversions.message}
                </div>
              )}
            </div>
            <div>
              <label className="form-label">A Visitors</label>
              <input
                aria-invalid={errors.aVisitors ? "true" : "false"}
                {...register("aVisitors", {
                  required: "This number is required",
                  validate: {
                    largerThanConversions: (v) => {
                      const conversions = parseInt(
                        getValues("aConversions") as unknown as string
                      );
                      const samples = parseInt(v as unknown as string);
                      return (
                        samples > conversions ||
                        "Sample size needs to be larger than conversions"
                      );
                    },
                  },
                })}
                className="form-input"
              />
              {errors.aVisitors && (
                <div className="text-red-500 mt-2 text-xs" role="alert">
                  {errors.aVisitors.message}
                </div>
              )}
            </div>
            <div>
              <label className="form-label">B Conversions</label>
              <input
                aria-invalid={errors.bConversions ? "true" : "false"}
                {...register("bConversions", {
                  required: "This number is required",
                })}
                className="form-input"
              />
              {errors.bConversions && (
                <div className="text-red-500 mt-2 text-xs" role="alert">
                  {errors.bConversions.message}
                </div>
              )}
            </div>
            <div>
              <label className="form-label">B Visitors</label>
              <input
                aria-invalid={errors.bVisitors ? "true" : "false"}
                {...register("bVisitors", {
                  required: "This number is required",
                  validate: {
                    largerThanConversions: (v) => {
                      const conversions = parseInt(
                        getValues("bConversions") as unknown as string
                      );
                      const samples = parseInt(v as unknown as string);
                      return (
                        samples > conversions ||
                        "Sample size needs to be larger than conversions"
                      );
                    },
                  },
                })}
                className="form-input"
              />
              {errors.bVisitors && (
                <div className="text-red-500 mt-2 text-xs" role="alert">
                  {errors.bVisitors.message}
                </div>
              )}
            </div>
          </div>
          <input
            className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-4 text-center"
            type="submit"
            value="Calculate p-value"
          />
        </form>
        {pValue !== undefined && (
          <div className="my-16 text-center">
            <div className="">Your p-value is:</div>
            <div
              className={`py-8 mt-6 rounded-lg ${
                pValue > 0.05 ? "bg-red-100" : "bg-green-100"
              }`}
            >
              {pValue}
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default App;
